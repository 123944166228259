<template>
  <div class="page" v-loading="loading">
    <el-form
      style="width: 500px"
      class="save-content"
      label-width="100px"
      :model="form"
      :rules="rules"
      ref="saveForm"
    >
      <el-form-item label="组织介绍页：">
        <el-radio-group v-model="form.enable">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-show="form.enable">
        <el-form-item label="背景图：" prop="background">
          <single-media-wall
            v-model="form.background"
            :width="150"
            :height="150"
            ratio="750:525"
          >
            <p slot="info" class="img-tips">建议上传尺寸比例为750px*525px</p>
          </single-media-wall>
        </el-form-item>

        <el-form-item label="组织logo：" prop="logo">
          <single-media-wall
            v-model="form.logo"
            :width="150"
            :height="150"
            ratio="136:136"
          >
            <p slot="info" class="img-tips">建议上传尺寸比例为300px*300px</p>
          </single-media-wall>
        </el-form-item>
        <el-form-item label="组织名称：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="背景颜色：" prop="background_color">
          <el-color-picker v-model="form.background_color"></el-color-picker>
        </el-form-item>
        <el-form-item label="组织标签：">
          <el-radio-group v-model="form.tags_enable">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.tags_enable" label="组织标识：">
          <el-select disabled v-model="form.type" placeholder="请选择">
            <el-option
              v-for="t in typeList"
              :key="t.value"
              :label="t.label"
              :value="t.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.tags_enable" label="组织届数：">
          本届为
          <el-input
            disabled
            v-model.number="form.session_number"
            style="width: 80px; margin-right: 10px"
          />届
        </el-form-item>
      </div>
    </el-form>
    <FixedActionBar>
      <el-button @click="cancel">取消</el-button>
      <el-button @click="$emit('openCode')">预览</el-button>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import CycIntroducePage from '../../mixins/CycIntroducePage'
export default {
  mixins: [CycIntroducePage],
  components: { FixedActionBar, SingleMediaWall },
  model: {
    prop: 'previewForm',
    event: 'setPreviewForm',
  },
  props: {
    previewForm: Object,
    formData: Object,
  },
  data() {
    return {
      loading: true,
      key: 'base',
      rules: {
        background: [
          { required: true, message: '请选择背景图', trigger: 'change' },
        ],
        logo: [{ required: true, message: '请选择logo', trigger: 'change' }],
        name: [{ required: true, message: '请输入组织名称', trigger: 'blur' }],
        background_color: [
          { required: true, message: '请选择背景颜色', trigger: 'change' },
        ],
      },
      typeList: [
        {
          value: 0,
          label: '无',
        },
        {
          value: 1,
          label: '志愿服务组织',
        },
        {
          value: 2,
          label: '行业协会商会',
        },
        {
          value: 3,
          label: '慈善组织',
        },
        {
          value: 4,
          label: '公开募捐资格',
        },
        {
          value: 5,
          label: '工商业联合会',
        },
      ],
    }
  },
  computed: {
    form: {
      get() {
        console.log(this.$props)
        return this.$props.previewForm[this.key]
      },
      set(val) {
        let formData = { ...this.$props.previewForm }
        formData[this.key] = val
        this.$emit('setPreviewForm', formData)
      },
    },
  },
  methods: {
    saveConfig() {
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$emit('saveConfig', { key: this.key, config: this.form })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    cancel() {
      this.form = JSON.parse(JSON.stringify(this.$props.formData[this.key]))
    },
  },
  watch: {
    '$props.formData.base': {
      handler(val) {
        this.form = JSON.parse(JSON.stringify(val))
        this.loading = false
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.img-tips {
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
</style>
